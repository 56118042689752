(function(){"use strict";class g{constructor(){this.data={}}set(e,t){this.data[e]=t}get(e){return this.data[e]}}const r={Booster:class{constructor(e){this.options=e}log(e,t){const o=`
      display: inline-block;
      padding: 4px 6px;
      border-radius: 4px;
      line-height: 1.5em;
      color: #282735;
      background: linear-gradient(45deg,
        rgba(185, 205, 255, 0.4) 0%,
        rgba(201, 182, 255, 0.4) 33%,
        rgba(239, 184, 255, 0.4) 66%,
        rgba(255, 210, 177, 0.4) 100%);
        `,i=[`%c[${this.options.title}] ${e}. Link to documentation ${this.options.documentationLink}`,o];t?(console.group(...i),Array.isArray(t)?console.log(...t):console.log(t),console.groupEnd()):console.log(...i)}validate(e,t,o){if(!e.validate)return!0;if(typeof e.validate=="function"){if(!e.validate(o))return this.log(`Invalid value "${o}" for attribute "${t}"`),!1}else if(!e.validate.includes(o))return this.log(`Invalid value "${o}" for attribute "${t}"`,[`%cPossible values:%c
`+e.validate.map(i=>`• ${i}`).join(`
`),"font-weight: 700;","font-weight: initial;"]),!1;return!0}parse(e){const t=new g;for(const o in this.options.attributes){const i=this.options.attributes[o],s=e.getAttribute(o);if(!s){t.set(o,i.defaultValue);continue}if(!this.validate(i,o,s))continue;let a=s;i.parse&&(a=i.parse(s)??i.defaultValue),t.set(o,a)}this.options.apply.call(this,e,t)}getElements(){return document.querySelectorAll(`[${this.options.name}]`)}init(){this.getElements().forEach(t=>this.parse(t))}},validation:{isNumber:n=>!isNaN(Number(n))}},b=new r.Booster({name:"fb-cookie",attributes:{"fb-cookie-name":{defaultValue:"cookie_consent"},"fb-cookie-value":{defaultValue:"accepted"},"fb-cookie-duration":{defaultValue:182,validate:r.validation.isNumber,parse:Number}},apply(n,e){var f;const t=n;t.style.display="none";const o=n.querySelectorAll("[fb-cookie-hide]");if(!o.length)return this.log("Required attribute is missing");const i=e.get("fb-cookie-name"),s=e.get("fb-cookie-value"),a=e.get("fb-cookie-duration");if(!i||!s||!a)return this.log("Required attribute is missing");const m=()=>{const l=a*24*60*60;document.cookie=`${i}=${s}; max-age=${l};`},u=()=>{m(),d()},h=()=>{t.style.display="block",o.forEach(l=>l.addEventListener("click",u))},d=()=>{t.remove(),o.forEach(l=>l.removeEventListener("click",u))};(f=document.cookie)!=null&&f.includes(`${i}=${s}`)?d():h()},title:"Cookies Booster",documentationLink:"https://www.flowbase.co/booster/cookies"}),c=()=>b.init();document.readyState==="complete"?c():window.addEventListener("load",c)})();
